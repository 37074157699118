<template>
  <div class="filters">
    <div
      v-show="allowGranularity"
      class="weekly"
    >
      <b-field
        label="Granularity"
        label-position="on-border"
      >
        <b-radio-button
          v-model="granularity"
          native-value="weekly"
          type="is-primary is-light is-outlined"
        >
          Weekly
        </b-radio-button>

        <b-radio-button
          v-model="granularity"
          native-value="monthly"
          type="is-primary is-light is-outlined"
        >
          Monthly
        </b-radio-button>
      </b-field>
    </div>

    <div>
      <b-field
        label-position="on-border"
      >
        <template
          v-for="v in variantOptions"
        >
          <b-radio-button
            :key="v.key"
            v-model="variant"
            :native-value="v.key"
            type="is-primary is-light is-outlined"
          >
            {{ v.name }}
          </b-radio-button>
        </template>
      </b-field>
    </div>

    <div>
      <b-field
        label-position="on-border"
        :label="variantOptions2.title"
      >
        <template
          v-for="v in variantOptions2.list"
        >
          <b-radio-button
            :key="v.key"
            v-model="variant2"
            :native-value="v.key"
            type="is-primary is-light is-outlined"
          >
            {{ v.name }}
          </b-radio-button>
        </template>
      </b-field>
    </div>

    <div
      v-show="allowDateRange"
      class="date"
    >
      <b-field
        label="From - To"
        label-position="on-border"
      >
        <b-datepicker
          v-model="theDateRange"
          type="month"
          position="is-bottom-left"
          :min-date="minDate"
          :max-date="maxDate"
          range
        />
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Filters',
  props: {
    allowDateRange: {
      type: Boolean,
      default: true,
    },
    allowFuture: {
      type: Boolean,
      default: false,
    },
    allowGranularity: {
      type: Boolean,
      default: true,
    },
    variantOptions: {
      type: Array,
      default() {
        return [{
          key: 'product',
          name: 'Product',
        }, {
          key: 'country',
          name: 'Country',
        }, {
          key: 'lunigroup',
          name: 'Luni Group',
        }];
      },
    },
    variantOptions2: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const today = new Date();
    return {
      minDate: new Date(2017, 0, 1),
      maxDate: this.allowFuture ? null : new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    };
  },
  computed: {
    ...mapGetters('filters', ['getGranularity', 'getVariant', 'getVariant2', 'getDateRange', 'getDateRangeFuture']),
    granularity: {
      get() {
        return this.getGranularity;
      },
      set(value) {
        this.filterGranularity(value);
      },
    },
    variant: {
      get() {
        return this.getVariant;
      },
      set(value) {
        this.filterVariant(value);
      },
    },
    variant2: {
      get() {
        return this.getVariant2[this.variantOptions2.key];
      },
      set(value) {
        const keyValue = {
          [this.variantOptions2.key]: value,
        };
        this.filterVariant2(keyValue);
      },
    },
    theDateRange: {
      get() {
        return this.allowFuture ? this.getDateRangeFuture : this.getDateRange;
      },
      set(value) {
        return this.allowFuture ? this.filterDateRangeFuture(value) : this.filterDateRange(value);
      },
    },
  },
  methods: {
    ...mapActions('filters', ['filterGranularity', 'filterVariant', 'filterVariant2', 'filterDateRange', 'filterDateRangeFuture']),
  },
};
</script>

<style scoped lang="scss">
.filters {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
